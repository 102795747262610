import { signify } from "react-signify";

export const sUser = signify(
  {
    name: "VietCPQ",
    info: {
      age: 0,
    },
  },
  {
    syncKey: "abc",
  }
);
