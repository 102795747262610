import React from "react";
import Info from "./Info";
import { sUser } from "./store";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";

export default function App() {
  return (
    <BrowserRouter>
      <Link to={"/"}>Home</Link>
      <br />
      <Link to={"product"}>Product</Link>

      <Routes>
        <Route path="/" element={<h1>HOME</h1>} />
        <Route path="product" element={<h1>PRODUCT</h1>} />
      </Routes>
    </BrowserRouter>
  );
}
